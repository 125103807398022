<script setup lang="ts">
async function submit() {
  if (email.value && !loading.value && !subscribed.value) {
    loading.value = true
    await useFetch('/api/subscribe', { method: 'POST', body: { email: email.value } })
    subscribed.value = true
  }
}

const email = ref('')
const loading = ref(false)
const subscribed = ref(false)
</script>

<template>
  <p v-if="subscribed" class="subscribed-message">{{ $t('subscribe.success') }}</p>
  <form v-else :class="{ loading }" @submit.prevent="submit()">
    <input v-model="email" id="email" :placeholder="$t('subscribe.emailPlaceholder')" type="email" />
    <AppButton class="submit-button" type="submit">{{ $t('subscribe.submitButton') }}</AppButton>
  </form>
</template>

<style lang="scss" scoped>
form {
  display: flex;
  height: 50px;

  > * {
    height: 100%;
    box-sizing: border-box;
  }
}
p + form {
  margin-top: 20px;
}
input {
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 1em;
  color: var(--text);
  background-color: var(--background-subtle);
  border: 2px solid var(--border);
  border-radius: 999px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &:focus {
    outline-color: var(--text);
  }
}
.submit-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 12px;
}
.subscribed-message {
  display: inline-block;
  background-color: var(--background-hover);
  padding: 15px 25px;
  border-radius: 999px;
  border: 1px solid var(--primary);
}

.loading {
  opacity: 0.6;
  pointer-events: none;
}
</style>
